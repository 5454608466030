import { Injectable, EventEmitter } from '@angular/core';
import { AppHttpService } from '../../../app-http.service';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { IAvos } from '../../../contracts/IAvos'; 
import { ImageObject } from '../../../contracts/Image.object';
import 'rxjs/add/operator/toPromise'

import { ApiService } from '../../../api.service';
import { HttpClient } from '@angular/common/http';

import {catchError, map} from 'rxjs/operators';

@Injectable()

export class AvosService {

    public url = 'avos';

    private imageUrl = environment.upload_server + '/avos'

    constructor(public api: ApiService, public http: HttpClient){}


    create(avos){
        return this.api.post(this.url, avos);
    }

    upload(data: Object){
        return this.api.post(this.url + '/upload', data)
    }

    list(): Observable<IAvos[]>{
        return this.api.get(this.url + '/list');
    }

    remove(id: number): Observable<IAvos[]>{
        return this.api.delete(this.url + '/delete/' + id)
    }

    update(id: number, avos): Observable<IAvos>{
        return this.api.post(this.url + '/' + id, avos);
    }
    
    get(id: number): Observable<IAvos>{
        return this.api.get(this.url + '/' + id);
    }



}
