<div class="col-md-12 col-lg-12 col-xxxl-3">
  <nb-card class="form-input-card">
    <nb-card-header>Tem certeza que deseja excluir?</nb-card-header>
    <nb-card-body class="result-from-dialog text-right">
      <button nbButton (click)="cancel()" status="danger" class="pull-right">Não</button>
      <button nbButton (click)="doAction()" class="space-left-btn pull-right">Sim</button>
      <br>
    </nb-card-body>
  </nb-card>
</div>
